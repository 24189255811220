.body-1 {
    font-size: 16px;
}

.body-2 {
    font-size: 14px;
}

.caption {
    font-size: 12px;
}

.input {
    font-size: 10px;
}

.h1,
h1 {
    font-size: 32px;
}

.h2,
h2 {
    font-size: 20px;
}

.h3,
h3 {
    font-size: 18px;
}

.h4,
h4 {
    font-size: 16px;
}

.h5,
h5 {
    font-size: 14px;
}

.h6,
h6 {
    font-size: 12px;
}