::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $divider;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $divider;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $divider;
}

.form-group {
    margin-bottom: 12px;

    &.disabled {
        opacity: 0.5;
    }
}

.ngx-select-disabled {
    user-select: all;
}

.form-select {
    &:disabled {
        background-color: $light !important;
        user-select: all;
    }
}

.form-control,
.form-select {
    min-height: 40px;
}

.input-group {
    flex-wrap: nowrap;
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;

    &.disabled {
        opacity: 0.5;
    }

    .input-group-text,
    .form-control,
    .form-select,
    .form-floating {
        border: none;
    }

    ngx-select {
        flex: 1;
        margin-left: 0 !important;
    }

    &.with-divider {
        & > * {
            border-left: 1px solid $divider;

            &:first-child {
                border-left: none;
            }
        }
    }
}
