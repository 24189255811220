@import "var";
@import "common";

@import "../../node_modules/bootstrap/scss/bootstrap";

@import "./partials/_variables";

@import '@angular/cdk/overlay-prebuilt.css';
@import './node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../../node_modules/angular-calendar/css/angular-calendar.css";
@import "~snazzy-info-window/dist/snazzy-info-window.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
@import "../../node_modules/ngx-toastr/toastr";
@import "~@trimblemaps/trimblemaps-js/trimblemaps.css";

@import "./partials/avatar";
@import "./partials/datepicker";
@import "./partials/button";
@import "./partials/dialog";
@import "./partials/dropdown";
@import "./partials/form";
@import "./partials/pill";
@import "./partials/typography";
@import "./partials/table";
@import "./partials/tabs";
@import "./partials/quill-editor";
@import "./partials/card";

@import "./styles-global";

html {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
}

a {
    color: #808080;
    text-decoration: none !important;
}

button:focus {
    outline: none;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $paper;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $gray-500;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $gray-700;
}

i[class^="icon-"],
i[class*=" icon-"] {
    vertical-align: middle;
    display: inline-flex;
}

.no-wrap {
    flex-wrap: nowrap;
    white-space: nowrap;
}

.paper {
    background-color: $paper;
    border-radius: 4px;
}

.page-container {
    padding: 30px;
    display: block;
}

.divider {
    padding-top: 14px;
    margin-bottom: 14px;
    border-bottom: 1px solid #e1e8ed;

    &.spacing-sm {
        padding-top: 10px;
        margin-bottom: 10px;
    }

    &.spacing-lg {
        padding-top: 16px;
        margin-bottom: 16px;
    }

    &.spacing-xl {
        padding-top: 20px;
        margin-bottom: 20px;
    }
}

.divider-bottom {
    border-bottom: 1px solid $divider;
}

.divider-top {
    border-top: 1px solid $divider;
}

.divider-right {
    border-right: 1px solid $divider;
}

.divider-left {
    border-right: 1px solid $divider;
}

.search-spinner {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    .loader {
        width: 22px;
        height: 22px;
        border: 2px solid;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
}
.cdk-stop-placeholder {
    height: 100px;
    padding: 6px;
    background: #f0f0f0;
    width: 100%;
    flex-direction: column;
    white-space: normal;
    border: 2px dashed $divider;
    border-radius: 4px;
    flex-shrink: 0;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
