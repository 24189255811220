.btn {
    display: inline-flex;
    align-items: center;
    min-height: 40px;
    font-weight: 600;
    cursor: pointer;
    appearance: none;
    white-space: nowrap;

    &.btn-sm {
        min-height: 0;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }


    .icon {
        margin-right: 8px;
    }

    .icon-end {
        margin-right: 0px;
        margin-left: 8px;
    }
}

// .btn-check:focus+&,
// &:focus {
//     color: var(--bs-btn-hover-color);
//     @include gradient-bg(var(--bs-btn-hover-bg));
//     border-color: var(--bs-btn-hover-border-color);
//     outline: 0;

//     // Avoid using mixin so we can pass custom focus shadow properly
//     @if $enable-shadows {
//         box-shadow: var(--bs-btn-box-shadow),
//             var(--bs-btn-focus-box-shadow);
//     }

//     @else {
//         box-shadow: var(--bs-btn-focus-box-shadow);
//     }
// }


.btn-icon {
    line-height: 1em;
    height: 40px;
    width: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .icon {
        margin: 0;
    }

    &:hover {
        border-color: transparent;
    }

    &.btn-lg {
        height: 48px;
        width: 48px;
    }

    &.btn-sm {
        height: 26px;
        width: 26px;
    }
}

.btn-outline-light {
    color: inherit;
    &:disabled {
        color: $gray-300;
    }
}

.btn-outline-light,
.btn-outline-primary,
.btn-outline-danger,
.btn-outline-warning,
.btn-outline-success,
.btn-outline-secondary {
    background-color: $gray-100;
    border-color: $gray-200;

    &:focus {
        color: inherit;
        background-color: $gray-100 !important;
        border-color: $gray-200 !important;
    }
}

.btn {
    --#{$prefix}btn-hover-border-color: transparent;
}


.btn-link {
    text-decoration: none;

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

.btn-group {
    padding: 2px;
    border: 1px solid $divider;
}

.btn-icon-text {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}