$assetsPath: "/assets";

$body-bg: #eef4f9;
$paper: #fff;
$divider: #e5e7eb;
$card-bg: #f5f8fa;
$background-color: #f5f8fa;

$primary: #00bad7;
$secondary: #4cb758;
$blue: #0178fa;
$success: #4cb75c;
$danger: #e9567a;
$warning: #f79009;
$info: #0178fa;
$light: #f5f8fa;
$purple: #9251de;
$yellow: #e2c768;
$body-color: #647785;
$gray: #d0d9e0;
$gray-50: #fbfbfb;
$gray-100: #f9fafb;
$gray-200: #e5e7eb;
$gray-300: #aab8c1;
$gray-400: #aab8c1;
$gray-500: #82949f;
$gray-700: #5d666d;
$text-muted: $gray-300;

$theme-colors: (
    "body-bg": $body-bg,
    "body-color": $body-color,
    "card-bg": $card-bg,
    "primary": $primary,
    "blue": $blue,
    "success": $success,
    "secondary": $secondary,
    "danger": $danger,
    "warning": $warning,
    "yellow": $yellow,
    "info": $info,
    "light": $light,
    "purple": $purple,
    "muted": $text-muted,
    "gray": $gray,
    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "gray-300": $gray-300,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "gray-700": $gray-700,
);

$box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);

// $font-size-sm: 12px;
// $font-size-lg: 16px;

// $h1-font-size: 56px;
// $h2-font-size: 48px;
// $h3-font-size: 40px;
// $h4-font-size: 32px;
// $h5-font-size: 24px;
// $h6-font-size: 20px;

// Common
$min-contrast-ratio: 2.3 !default;
$border-radius: 4px;

// Grid
$grid-columns: 12;
$grid-gutter-width: 16px;

// Heading
$headings-color: $primary;
$headings-font-weight: 700;

// FormControl
$input-bg: $paper;
// $input-color: $body-color;
$input-border-color: $divider;
$input-placeholder-color: $text-muted;
$input-font-size: 14px;
$input-padding-y: 8px;
$input-padding-x: 12px;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-focus-border-color: $divider;
// $input-focus-color: $text-muted;
$form-select-border-color: $divider;
$form-select-box-shadow: none;
$form-select-focus-box-shadow: none;
// $form-select-color: $body-color;
$form-select-indicator-color: $text-muted;
$input-group-addon-bg: $input-bg;
$input-group-addon-border-color: transparent;

// Checkbox
$form-check-input-bg: $paper;
$form-check-input-width: 1.4em;
$form-check-input-border: 1px solid $gray-500;
$form-check-input-focus-box-shadow: none;
$form-check-input-focus-border: $gray-500;
$form-check-input-checked-color: white;
$form-check-input-checked-bg-color: $paper;
$form-check-input-checked-border-color: $primary;
$form-check-input-checked-bg-color: $primary;

// Dialog
$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;
$modal-footer-margin-between: 20px;
$modal-sm: 380px;
$modal-md: 620px;
$modal-lg: 880px;
$modal-xl: 1000px;
$modal-inner-padding: 24px;
$modal-header-padding-y: 24px;
$modal-header-padding-x: 24px;
$modal-content-bg: $card-bg;

//Button
$btn-padding-y: 8px;
$btn-padding-x: 16px;

// badge / pill
// $badge-border-radius: 100px;

// Dropdown
$dropdown-link-color: $body-color;
$dropdown-box-shadow: $box-shadow;
$dropdown-bg: $paper;

// List
$list-group-color: $body-color;
$list-group-border-color: rgba(0, 0, 0, 0.125);
$list-group-bg: $paper;

//progress-bar
$progress-height: 4px;
$progress-bg: #ebf2f3;

// Form
$form-label-font-size: 10px;
$form-label-font-weight: 600;
$form-label-margin-bottom: 4px;
$form-label-color: #5d666d;
$form-select-disabled-bg: $gray-200;
$input-disabled-bg: $card-bg;

// Card
$card-bg: $paper;

$grid-breakpoints: (
    xs: 0,
    sm: 640px,
    md: 768px,
    lg: 1180px,
    xl: 1280px,
    xxl: 1536px,
);

// Table
$table-color: $body-color;
$table-bg: transparent;

// Pagination
$pagination-bg: $paper;
$pagination-disabled-bg: $paper;

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px,
//   xxl: 1320px
// );

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
