.avatar {
  height: 26px;
  width: 26px;
  border-radius: 150px;
  overflow: hidden;
  display: block;
  object-fit: cover;
  &.avatar-xxl {
    height: 150px;
    width: 150px;
  }
  &.avatar-xl {
    height: 76px;
    width: 76px;
  }
  &.avatar-lg {
    height: 42px;
    width: 43px;
  }
  &.avatar-sm {
    height: 22px;
    width: 22px;
  }
  &.avatar-xs {
    height: 18px;
    width: 18px;
  }
  &.avatar-xs {
    height: 18px;
    width: 18px;
  }
}
