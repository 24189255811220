.modal-content {
  .modal-header {
    display: flex;
    align-items: center;
    padding-bottom: 8px;

    &.divider {
      margin: 0;
    }

    .btn-close {
      position: absolute;
      right: 16px;
      top: 16px;

      &:focus {
        box-shadow: none;
      }
    }

    .modal-title {
      width: 100%;
      flex-shrink: 1;
    }

    .actions {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  .modal-footer {
    padding-top: 24px;

    &.divider {
      margin: 0;
      border-top: 1px solid $divider;
      border-bottom: none;
    }

    &>* {
      margin: 0 0 0 var(--bs-modal-footer-gap);
      &:first-child{
        margin-left: 0;
      }
    }
  }
}