.bol-document-print {
    table {
        border-color: #bfc7cd;
        border-collapse: collapse;
    }
    table[cellpadding="1"] {
        thead,
        tbody,
        tfoot,
        tr,
        td,
        th {
            padding: 4px;
        }
    }
    table[border="1"] {
        thead,
        tbody,
        tfoot,
        tr,
        td,
        th {
            border-width: 1px;
        }
    }

    .main-container {
        margin: 20px;
        padding: 10px;
        max-width: 500;
        margin: 0 auto;
    }

    .header {
        position: relative;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .header .lot-number {
        position: absolute;
        top: 6px;
        right: 4px;
    }

    .header .logo {
        display: flex;
        justify-content: center;
    }

    .delivery-to-address {
        font-size: 20px;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .qr-code-container {
        display: flex;
        width: 100%;
        align-items: center;
    }

    .qr-code-container .qr-code {
        flex-shrink: 0;
    }

    .qr-code-container .qr-code .qr-code-img {
        height: 150px;
        width: auto;
    }

    .qr-code-container .order-number {
        flex-shrink: 1;
        width: 100%;
        height: 146px;
        font-size: 42px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
    }

    .traking-number-company-container {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 2px solid #000;
    }

    .arrow {
        display: none;
    }

    .traking-number-company div:first-child {
        font-size: 24px;
    }

    .footer-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .notes-container {
        padding-top: 10px;
        padding-bottom: 80px;
    }

    .footer-container .footer-logo .footer-logo-img {
        max-width: 90px;
    }
}
