@use "common/var" as *;

app-quote-list,
app-customer-list {
    display: block;
    height: 100%;
}

app-customer-list {
    display: flex;
    flex-flow: column;
}

app-pickups {
    display: block;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Global ngb-typeahead-window styles */

ngb-typeahead-window {
    max-height: 300px;
    overflow: auto;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.pointer {
    cursor: pointer;
}

.text-light::placeholder {
    color: #b3b3b3 !important;
}

.text-darker {
    color: #7b7f90 !important;
}

.fs14 {
    font-size: 14px;
}

.fs16 {
    font-size: 16px;
}

//User Roles
.role-dispatcher,
.role-Dispatcher {
    color: #7247c3;
}
.role-sales,
.role-Sales {
    color: #0009e0;
}
.role-accounting,
.role-Accounting {
    color: #c34747;
}
.role-admin,
.role-Admin {
    color: #029518;
}
.role-manager,
.role-Manager {
    color: #12b9d8;
}
.role-other,
.role-Other {
    color: #808080;
}
// General
.active-route-name {
    h1 {
        font-size: 26px;
        color: #808080;

        small {
            font-size: 18px;
            color: #b3b3b3;
        }
    }

    margin-bottom: 0;
}

.settings-save-btn {
    justify-content: center;
    min-height: 40px;
    padding: 8px 60px;
}

.pill {
    height: 22px;
    border: 1px solid;
    border-radius: 12px;
    font-size: 12px;
    padding: 0 14px;
    line-height: 20px;
}

.min-w-0 {
    min-width: 0;
}

.custom-accordion {
    border-radius: 2px;
    box-shadow: 1px 1px 16px 0 rgba(0, 0, 0, 0.1);
    border: 0;
    margin-bottom: 40px;

    .card-header {
        background: #fff;
        padding: 20px 20px 20px 40px;
    }

    .panel-heading:after {
        content: "";
        position: absolute;
    }

    .accordion-header {
        color: #aaa;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        outline: none;
        cursor: pointer;
        font-size: 18px;
    }

    h5.accordion-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        padding-bottom: 20px;
        padding-left: 15px;
    }

    .accordion-body {
        padding: 40px 40px 0 40px;
        margin-bottom: 40px;

        &.without-margins {
            padding: 0;
            margin: -20px -20px 0;

            > div:not(.top-border) {
                padding: 40px 60px 0;
            }

            .top-border {
                border-top: 2px solid #f5f5f5;
                padding: 40px;
            }
        }
    }

    .accordion-footer {
        display: flex;
        align-items: center;
        padding: 0 50px 30px 50px;
        justify-content: space-between;
    }

    .accordion-footer-bordered {
        border-top: 1px solid #ddd;
        padding-top: 50px;
    }

    .subtitle {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 20px;

        span {
            font-size: 12px;
        }
    }

    .minus-button {
        width: 31px;
        height: 31px;
        background-color: #f4f9f0;
        border-radius: 50%;
        border: none;
        position: relative;
        margin-top: 36px;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 14px;
            height: 2px;
            background-color: #000;
        }
    }
}

.snf {
    border-radius: 22px;

    .popover-content.popover-body {
        padding: 18px;
    }

    .cntnt_wrpr {
        // width: 190px;
        // height: 130px;

        h5 {
            text-align: center;
            font-family: "Montserrat";
            font-size: 14px;
            color: #808080;
            margin-bottom: 16px;
        }

        .ftr {
            justify-content: space-between;
            margin-top: 20px;

            button {
                background: none;
                border: none;
                font-family: "Montserrat";
                font-size: 10px;

                &:nth-child(1) {
                    color: #808080;
                }

                &:nth-child(2) {
                    color: #0078ff;
                }
            }
        }
    }
}

// * {
//     background-color: red !important;
// }

.dn {
    display: none;
}

.dn-important {
    display: none !important;
}

.hidden {
    visibility: hidden;
}

// service type popup styling

.service_type_modal {
    .hdr {
        height: 46px;
        padding: 0px 40px 0 20px;
        margin-bottom: 100px;
        position: relative;

        button.close.pull-right {
            position: absolute;
            top: 28px;
            right: 38px;
            color: #d9d9d9;
            font-size: 30px;
        }
    }

    .pp_content {
        padding: 0px 56px 50px;

        .type_selection {
            height: 178px;
            justify-content: space-around;
            margin-bottom: 50px;

            .sec {
                height: 178px;
                width: 188px;
                align-items: center;
                justify-content: center;
                flex-flow: column;
                border: 2px solid #f1f1f1;
                border-radius: 24px;
                transition: all 0.2s;
                cursor: pointer;

                &.selected {
                    border-color: #1aade5;
                }

                .icon_wrpr {
                    height: 98px;
                    width: 143px;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 8px;

                    .icon {
                        &.bnc {
                            background-image: url($assetsPath + "/images/b_and_c.png");
                            height: 92px;
                            width: 83px;
                        }
                        &.pallet {
                            background-image: url($assetsPath + "/images/pallet.png");
                            height: 68px;
                            width: 98px;
                        }
                        &.fl {
                            background-image: url($assetsPath + "/images/full_load.png");
                            height: 53px;
                            width: 128px;
                        }
                        &.cfs {
                            background-image: url($assetsPath + "/images/cfs.png");
                            height: 50px;
                            width: 142px;
                        }
                    }
                }
            }
        }

        .line {
            .dd_wrpr {
                width: 100%;
                border: 2px solid #f1f1f1;
                border-radius: 40px;
                height: 40px;

                .form-control,
                .custom-select {
                    line-height: 38px;
                }
            }

            &.name_desc {
                .itm_name {
                    width: calc(40% - 20px);
                }

                .itm_desc {
                    width: calc(60% - 2px);
                    margin-left: 20px;
                }
            }

            &.name_desc .itm_desc input,
            .itm_cbm input,
            .itm_notes input {
                height: 40px;
            }

            &.msrmnts {
                .itm_dims .dims_wrpr input.sec,
                .itm_weight input.sec {
                    border: 2px solid #f1f1f1;
                    border-width: 2px 1px;
                    color: #808080;
                    text-align: center;
                    padding: 0px 6px;
                }

                .itm_dims .dims_wrpr input.sec:nth-child(1),
                .itm_weight input.sec {
                    border-radius: 30px 0px 0px 30px;
                    border-left-width: 2px;
                }

                .itm_dims .dims_wrpr .dd_wrpr,
                .itm_weight .dd_wrpr {
                    height: 40px;
                    border: 2px solid #f1f1f1;
                    border-radius: 0px 30px 30px 0px;
                    border-width: 2px 2px 2px 1px;

                    .form-control,
                    .custom-select {
                        line-height: 40px;
                        height: 40px;
                    }
                }

                .itm_dims {
                    .dims_wrpr {
                        height: 40px;
                        align-items: center;

                        .sec {
                            height: 100%;
                            border: 2px solid #f1f1f1;
                            width: 42px;
                            align-items: center;
                            justify-content: center;
                            color: #b3b3b3;

                            &:focus {
                                outline: none;
                            }

                            &:nth-last-child(1) {
                                border-radius: 0px 30px 30px 0px;
                                border-right-width: 2px;
                            }
                        }

                        input.sec {
                            width: 46px;
                        }
                    }
                }

                .inpt_wrpr {
                    margin-left: 20px;

                    &:first-of-type,
                    &.itm_load_size {
                        margin-left: 0;
                    }
                }

                .itm_weight {
                    input.sec {
                        width: 86px;
                    }
                }
            }

            .itm_weight,
            .itm_accessories,
            .itm_notes,
            .itm_pallet_size,
            .itm_load_size {
                width: 100%;
            }

            .itm_weight {
                max-width: 260px;
            }

            &.checkboxes {
                align-items: center;

                .inpt_wrpr {
                    margin-right: 40px;
                    color: #b3b3b3;

                    &.upld_files {
                        color: #d8d8d8;
                    }

                    label[for="upload_file*"] {
                        height: 35px;
                        width: 35px;
                        border: 1.5px solid #f1f1f1;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        position: relative;

                        span:not(.numbers-uploaded) {
                            transform: rotate(45deg);
                            font-size: 16px;
                            font-weight: normal;
                        }
                    }

                    span.numbers-uploaded {
                        position: absolute;
                        background-color: $primary;
                        color: #fff;
                        font-size: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 12px;
                        width: 12px;
                        border-radius: 50%;
                        bottom: -6px;
                        right: -6px;
                    }
                }
            }

            .itm_pallet_size {
                .form-control {
                    overflow: hidden;
                    white-space: nowrap;
                }

                .itm_dims {
                    margin-bottom: 0;
                    border-top: 2px solid #f1f1f1;
                    width: 100%;
                    margin-top: 8px;

                    .dims {
                        transform: scale(0.7);
                    }
                }
            }

            &.btn-line .btn[type="submit"] {
                padding-left: 50px;
                padding-right: 50px;
            }

            .itm_load_size .dropdown-item span {
                display: inline-block;
                vertical-align: middle;
                width: 70px;
                text-align: right;
                padding-right: 10px;
            }
        }

        > div:not(.type_selection) {
            .inpt_wrpr {
                margin-bottom: 35px;
            }
        }
    }

    &.delivery-modal,
    &.new-customer-modal {
        .hdr {
            margin-bottom: 0;
            height: auto;
        }
    }
}

.new-customer-modal {
    max-width: 705px;

    .form-group {
        label {
            margin-left: 16px;
        }
    }

    *:not(.btn) {
        color: var(--secondary);
    }

    .form-group {
        margin-bottom: 0;
    }

    .dd_wrpr .form-control {
        line-height: 40px;
    }

    .itm-company-name {
        width: 65%;
    }

    .itm-broker-account {
        width: calc(35% - 80px);
        margin-top: 30px;
    }

    .itm-company-phone,
    .itm-company-location {
        width: 40%;
    }

    .itm-how-did-you-hear,
    .itm-sals-rep,
    .itm-first-name,
    .itm-last-name,
    .itm-your-email {
        width: calc(50% - 10px);
    }

    .itm-city {
        width: calc(23% - 20px);
    }

    .itm-state {
        width: calc(17% - 20px);
    }

    .itm-zip-code {
        width: calc(20% - 20px);
    }

    .itm-company-email {
        width: calc(60% - 20px);
    }

    .itm-contact-number,
    .itm-cell-number {
        width: calc(25% - 15px);
    }

    h4 {
        padding-bottom: 15px;
        border-bottom: 1.5px solid #d9d9d9;
        margin-bottom: 24px;
    }

    .btn-save {
        margin-top: 30px;
    }
}

.itm-company-email {
    width: 100%;

    input {
        border-radius: 30px 0px 0px 30px;
    }

    .accounting,
    .delete-btn {
        border: 1.5px solid #f1f1f1;
    }

    .accounting {
        border-width: 1.5px 0;
        color: var(--primary);
        padding: 0 14px;
        transition: 0.3s all;
        cursor: pointer;
        min-width: 102px;
        justify-content: center;

        &:hover {
            color: #fff;
            background-color: var(--primary);
            border-color: var(--primary);
        }
    }

    .delete-btn {
        border-radius: 0 30px 30px 0;
        padding: 0 14px 0 12px;

        i {
            transition: 0.3s all;
        }

        &:hover i {
            color: #9a9a9a;
        }
    }
}

.delivery-modal,
.new-customer-modal,
.customer-payment-modal {
    .hdr {
        h3 {
            color: var(--secondary);
            font-weight: normal;
            margin: 40px 0px 24px 20px;
            padding-bottom: 15px;
            border-bottom: 1.5px solid #d9d9d9;
        }
    }

    .pp_content {
        padding: 0 40px 40px;
    }
}

.delivery-modal,
.new-customer-modal,
.summary-modal {
    .pp_content {
        .btn {
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}

.delivery-modal {
    .delivery-items-list {
        margin: 30px -20px 60px;
        width: calc(100% + 40px);
    }

    .custom-checkbox {
        color: #b3b3b3;

        label {
            color: var(--secondary);
        }
    }
}

.pickups_wrpr,
.delivery_wrpr {
    .quote-items {
        background-color: #fff;
        border-radius: 16px;
        margin-bottom: 24px !important;
        margin-top: 15px;
        border: 2px solid lightgrey;
        padding: 10px;

        a {
            cursor: pointer;
            color: #b9b9b9;
            margin-right: 5px;
        }
        .name {
            flex-grow: 1;
        }
        .alert {
            padding: 2px 10px 2px 5px;
            border-radius: 10px;
            font-size: 12px;
            svg {
                width: 10px;
                height: 10px;
                margin-left: 3px;
                margin-right: 10px;
                margin-bottom: 1px;
                color: red;
            }
        }
        .measurements {
            margin-top: 5px;
            color: #b9b9b9;
            font-size: 14px;
            justify-content: flex-end;
            white-space: nowrap;
            .alerts {
                flex-grow: 1;
                flex-wrap: wrap;
                justify-content: flex-start;
                .alert {
                    white-space: nowrap;
                }
            }

            padding-bottom: 10px;
            border-bottom: 1px solid #b9b9b9;
        }

        .icon {
            height: 22px;
            width: 22px;
            margin-right: 4px;

            &.hand_truck {
                background-image: url($assetsPath + "/images/handtruck.png");
            }
        }

        .qty-wrapper {
            color: #b9b9b9;
            margin-right: 5px;
            display: flex;
            height: 35px;
            width: 35px;
            border: 1.5px solid #f1f1f1;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            margin-top: -6px;
            text-decoration: none;
            &.unallocated {
                color: red;
                border-color: red;
            }
        }

        .upload-icon {
            margin-top: -6px;
        }

        .delivery-list-header {
            color: #b9b9b9;
            font-size: 12px;
            padding-left: 10px;
            padding-top: 5px;
        }
        .delivery-list-line {
            align-items: center;
            margin-top: 5px;
            .qty-input {
                padding-right: 0;
                &::-webkit-inner-spin-button {
                    opacity: 1;
                }
            }
            .plus-wrapper {
                margin-top: 2px;
                margin-left: 10px;
            }
        }
    }

    .new_pickup,
    .new_delivery {
        height: 110px;
        border-radius: 16px;
        font-size: 24px;
        font-family: "Montserrat";
        cursor: pointer;
        margin-bottom: 60px;
    }

    .pickup_sec,
    .delivery_sec {
        border: 1.5px solid #3ccae0;
    }

    .delivery_sec {
        border: 1.5px solid #f16484;
    }

    .pickup_sec,
    .delivery_sec {
        border-radius: 16px;
        margin-bottom: 24px;

        // * {
        //     margin-bottom: initial;
        // }

        .hdr {
            height: 96px;
            align-items: center;

            p {
                font-family: "Montserrat";
                font-size: 24px;
            }

            span {
                margin-left: 40px;
                height: 44px;
                width: 2px;
                background-color: #f0f0f0;
            }

            .dlt_btn {
                height: 20px;
                width: 16px;
                background-image: url($assetsPath + "/images/grey-delete.png");
                background-repeat: no-repeat;
                margin-left: 32px;
                margin-top: -3px;
                cursor: pointer;
            }
        }

        .hdr:not(.itm_line) {
            padding: 0px 20px;
        }
    }

    .items_sec {
        h4 {
            font-size: 24px;
            color: var(--secondary);
            padding: 0px 32px;
            margin-bottom: 10px;
        }
    }

    .items_list {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    }

    .itm_line {
        padding: 0px 32px;
        align-items: center;
        height: 54px;

        &:nth-child(odd) {
            background-color: #fafafa;
        }

        &:nth-child(1),
        &:nth-child(even) {
            background-color: #fff;
        }

        &.hdr {
            height: 44px;
            border: 2px solid #f5f5f5;
            border-width: 2px 0px;

            .sec {
                font-size: 10px;
                font-weight: bold;
                color: var(--secondary);
            }
        }

        .sec {
            flex-grow: 0;
            flex-shrink: 0;
            font-family: "Montserrat";
            font-size: 14px;
            color: var(--secondary);

            &.in {
                width: 13%;
            }

            &.it {
                width: 16%;
            }

            &.qty {
                width: 8%;
            }

            &.sz {
                width: 17%;
            }

            &.wt {
                width: 10%;
            }

            &.acc {
                width: 16%;

                .icon {
                    height: 22px;
                    width: 22px;
                    margin-right: 4px;

                    &.hand_truck {
                        background-image: url($assetsPath + "/images/handtruck.png");
                    }
                }
            }

            &.otr {
                width: 13%;
            }

            &.att {
                width: 6%;

                .upld_opnr {
                    height: 42px;
                    width: 42px;
                    border: 1.5px solid #f1f1f1;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;

                    .fas {
                        font-size: 16px;
                        transform: rotate(45deg);
                        font-weight: normal;
                        color: #d8d8d8;
                    }
                }
            }

            &.optns {
                color: #d8d8d8;

                .pointer.dropdown-toggle {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .itm_line_1 {
        border: 2px solid #f5f5f5;

        border-radius: 15px;
        padding: 0px 32px;
        align-items: center;
        height: 54px;

        &:nth-child(odd) {
            background-color: #fafafa;
        }

        &:nth-child(1),
        &:nth-child(even) {
            background-color: #fff;
        }

        &.hdr {
            height: 44px;
            border: 2px solid #f5f5f5;
            border-width: 2px 0px;

            .sec {
                font-size: 10px;
                font-weight: bold;
                color: var(--secondary);
            }
        }

        .sec {
            flex-grow: 0;
            flex-shrink: 0;
            font-family: "Montserrat";
            font-size: 14px;
            color: var(--secondary);

            &.in {
                width: 13%;
            }

            &.it {
                width: 16%;
            }

            &.qty {
                width: 8%;
            }

            &.sz {
                width: 17%;
            }

            &.wt {
                width: 10%;
            }

            &.acc {
                width: 16%;

                .icon {
                    height: 22px;
                    width: 22px;
                    margin-right: 4px;

                    &.hand_truck {
                        background-image: url($assetsPath + "/images/handtruck.png");
                    }
                }
            }

            &.otr {
                width: 13%;
            }

            &.att {
                width: 6%;

                .upld_opnr {
                    height: 42px;
                    width: 42px;
                    border: 1.5px solid #f1f1f1;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;

                    .fas {
                        font-size: 16px;
                        transform: rotate(45deg);
                        font-weight: normal;
                        color: #d8d8d8;
                    }
                }
            }

            &.optns {
                color: #d8d8d8;

                .pointer.dropdown-toggle {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .add_itm_btn {
        margin: 20px 24px;
        outline: none;
        color: #0078ff;
        border-color: #0078ff;
        border-radius: 0;
        border-width: 2px;
    }

    .remaining {
        color: #808080;
    }
}

.inpt_wrpr {
    margin-right: 5px;
    color: #b3b3b3;

    &.upld_files {
        color: #d8d8d8;
    }

    label[for="upload_file*"],
    label.upload-label {
        height: 35px;
        width: 35px;
        border: 1.5px solid #f1f1f1;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        position: relative;

        span:not(.numbers-uploaded) {
            transform: rotate(45deg);
            font-size: 16px;
            font-weight: normal;
        }
    }

    span.numbers-uploaded {
        position: absolute;
        background-color: $primary;
        color: #fff;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        bottom: -6px;
        right: -6px;
        padding-top: 1px;
    }
}

.pickup_dtls,
.delivery_dtls {
    padding: 0 5px;

    .dd_wrpr {
        border: 2px solid #f1f1f1;
        border-radius: 40px;
        height: 44px;
    }

    .line {
        margin-bottom: 30px;
        align-items: flex-end;
    }

    .inpt_wrpr {
        margin: 0px 11px;

        &:nth-child(1) {
            margin-left: 0px;
        }

        &:nth-last-child(1) {
            margin-right: 0px;
        }

        &.location {
            width: calc(40% - 11px);
        }

        &.name {
            width: calc(32% - 22px);
        }

        &.c_number {
            width: calc(27% - 11px);
        }

        &.pickup {
            width: 28%;
        }

        &.pu_time {
            width: 35%;
        }

        &.upld_files {
            label {
                height: 50px;
                width: 50px;
                border: 1.5px solid #f1f1f1;
                border-radius: 50%;
                transform: rotate(45deg);
                margin: 0;
                color: #d9d9d9;
                cursor: pointer;
                span:not(.numbers-uploaded) {
                    transform: rotate(45deg);
                    font-size: 16px;
                    font-weight: normal;
                }
            }

            span.numbers-uploaded {
                position: absolute;
                background-color: $primary;
                color: #fff;
                font-size: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                bottom: 13px;
                right: -11px;
                transform: rotate(-45deg);
            }
        }

        label {
            color: #808080;
            justify-content: center;
            align-items: center;
            margin-left: 5px;

            .fas {
                font-size: 24px;
                font-weight: normal;
            }
        }
    }
}

.delivery-items-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
    margin-bottom: 44px;

    .checkbox-name-wrpr {
        padding: 0;

        h3 {
            margin-bottom: 0;
            margin-left: 20px;
        }
    }

    .one-row {
        padding-right: 48px;
    }

    .one-row,
    .two-rows {
        width: 56%;
    }

    .two-rows {
        padding-top: 0;
        padding-bottom: 0;

        .div-row {
            padding: 12px 30px 12px 0;

            &:first-of-type {
                border-bottom: 1.5px solid #d9d9d9;
            }
        }
    }
}

.delivery-items-list li {
    border: 1.5px solid #d9d9d9;
    border-radius: 17px;
    margin-bottom: 12px;
    padding: 0 16px;

    .fa-arrow-right,
    .fa-ellipsis-h {
        color: #d9d9d9;
        font-weight: 400;
        font-size: 20px;
        cursor: pointer;
    }

    *:not(h3) {
        font-size: 13px;
        line-height: 1.8;
    }

    h3 {
        width: 120px;
        padding: 2px 0;
    }

    > div {
        padding: 12px 18px;
    }

    > div.qty_wrpr {
        padding: 0;
    }

    i {
        color: var(--purple);
        margin-right: 5px;
    }

    .green i {
        color: var(--success);
    }

    .date {
        margin-right: 10px;
    }
}

.qty_wrpr {
    height: 40px;
    align-items: center;
    padding: 0;

    .sec {
        height: 100%;
        border: 2px solid #f1f1f1;
        width: 42px;
        align-items: center;
        justify-content: center;
        color: #b3b3b3;

        &:focus {
            outline: none;
        }

        &:nth-child(1) {
            border-radius: 30px 0px 0px 30px;

            &:after {
                content: "";
                display: block;
                height: 15px;
                width: 15px;
                background-image: url($assetsPath + "/images/plus-minus.png");
            }
        }

        &:nth-last-child(1) {
            border-radius: 0px 30px 30px 0px;

            &:after {
                content: "";
                display: block;
                height: 15px;
                width: 15px;
                background-image: url($assetsPath + "/images/plus-minus.png");
                background-position: -15px 0px;
            }
        }
    }

    input.sec {
        border: 2px solid #f1f1f1;
        border-width: 2px 0px;
        width: 46px;
        color: #808080;
        text-align: center;
        padding: 0px 6px;
    }
}

.summary-modal {
    max-width: 740px;

    .hdr {
        margin-bottom: 0;
    }

    .width-45 {
        width: calc(45% - 50px);
    }

    .width-15 {
        width: 15%;
    }

    .width-20 {
        width: 20%;
    }

    .width-150 {
        width: 150%;
    }

    .summary-items-list {
        padding: 0;
        margin-bottom: 50px;
    }

    .summary-items-list li {
        border: 1.5px solid #d9d9d9;
        border-radius: 17px;
        margin-bottom: 12px;
        padding: 8px 16px;
        min-height: 64px;
    }

    *:not(.btn) {
        color: var(--secondary);
    }

    h3 {
        margin: 0;
    }

    .total-item-price {
        color: var(--primary);
        font-size: 30px;
    }

    .add-new-coast-item {
        cursor: pointer;
    }

    .add-new-coast-icon {
        border: 1px solid var(--primary);
        position: relative;

        &::before {
            position: absolute;
            content: "+";
            display: block;
            color: var(--primary);
            left: 50%;
            top: 50%;
            font-size: 22px;
            transform: translate(-50%, -52%);
        }
    }

    .add-new-coast-icon,
    .color-indicator {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        margin-right: 30px;
    }

    .total-price-table-wrpr {
        border: 1.5px solid #f1f1f1;
        border-radius: 22px;
        padding: 20px;

        table {
            width: 235px;
        }

        td {
            padding: 5px;

            &.price {
                text-align: right;
            }
        }

        .subtotal,
        .grand-total {
            border-top: 1.5px solid #f1f1f1;
        }

        .subtotal td.price {
            color: #1aade5;
        }

        .grand-total td.price {
            color: var(--primary);
        }
    }

    .form-group {
        label {
            margin-left: 16px;
        }
    }

    .input-and-select {
        input {
            border-radius: 30px 0px 0px 30px;
            border-left-width: 2px;
            border: 2px solid #f1f1f1;
            border-width: 2px 1px;
            color: #808080;
            text-align: center;
            padding: 0px 6px;
            width: 86px;
            line-height: 40px;
        }

        .dd_wrpr {
            height: 44px;
            border: 2px solid #f1f1f1;
            border-radius: 0px 30px 30px 0px;
            border-width: 2px 2px 2px 1px;
        }
    }

    .scale-wrpr {
        margin-top: 40px;
        padding-bottom: 100px;

        .cost,
        .markup {
            width: 60px;
            font-size: 16px;

            span {
                font-size: 12px;
            }
        }
    }

    .scale {
        height: 30px;
        width: calc(100% - 120px - 30px);

        .first-scale,
        .second-scale {
            height: 100%;
        }

        .first-scale {
            background-color: var(--primary);
            border-radius: 15px 0 0 15px;
        }

        .second-scale {
            background-color: var(--success);
            border-radius: 0 15px 15px 0;
        }
    }

    .red-point {
        position: absolute;
        border: 1px solid #fff;
        border-radius: 50%;
        background-color: #ce2a2a;
        height: 20px;
        width: 20px;
        bottom: -10px;
        left: 60%;

        .label {
            position: absolute;
            background-color: #fff;
            border: 2px solid #d9d9d9;
            top: 35px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 10px;
            line-height: 1;
            padding: 6px 10px;
            font-size: 24px;

            &::before {
                content: "";
                display: block;
                border-color: #d9d9d9;
                border-style: solid;
                height: 15px;
                width: 15px;
                position: absolute;
                top: -9px;
                border-width: 1.5px 1.5px 0 0;
                background-color: #fff;
                transform: rotate(-45deg) translate(-5px, -5px);
                left: 50%;
            }

            span {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-size: 14px;
            }

            span:first-of-type {
                right: 110%;
            }

            span:last-of-type {
                left: 110%;
            }
        }
    }

    .chart-container {
        height: 150px;
        width: 150px;
        margin-right: 50px;
        margin-bottom: 40px;
    }

    ul.markers-wrpr {
        list-style-type: none;
        padding: 0;
        margin-top: 30px;

        .marker-item {
            font-size: 18px;
            width: 40%;
            margin-bottom: 12px;

            span {
                height: 18px;
                width: 18px;
                border-radius: 50%;
                margin-right: 12px;
            }

            .green {
                background-color: rgb(44, 204, 113);
            }

            .red {
                background-color: rgb(204, 0, 0);
            }

            .blue {
                background-color: rgb(0, 202, 255);
            }

            .purple {
                background-color: rgb(90, 81, 222);
            }

            .orange {
                background-color: rgb(243, 156, 19);
            }

            .white {
                /*background-color: #fff;
                border: 2px solid var(--secondary);*/
                background-color: rgb(214, 19, 243);
            }
        }
    }
}

.color-indicator {
    &.green {
        background-color: #2ccc71;
    }

    &.red {
        background-color: #cc0000;
    }

    &.blue {
        background-color: #00caff;
    }

    &.purple {
        background-color: #5a51de;
    }

    &.orange {
        background-color: #f39c13;
    }
}

@media (min-width: 1200px) {
    .delivery-modal {
        max-width: 1200px;
    }
}

.cal-month-view,
.cal-month-view {
    padding: 0 10px 8px;

    .cal-day-cell {
        height: 30px;
        line-height: 30px;

        &.cal-today .cal-day-number {
            font-size: 12px;
            color: var(--primary);
        }
    }

    .cal-day-cell,
    .cal-cell-top {
        min-height: unset;
        text-align: center;
    }

    .cal-day-cell,
    .cal-cell-row,
    .cal-days {
        border: none !important;
    }

    .cal-day-number {
        opacity: 1;
        margin: 0;
        float: none;
        font-size: 12px;
        color: var(--secondary);
    }
}

.cal-month-view .cal-day-cell.cal-today,
.cal-month-view .cal-cell-row:hover {
    background-color: transparent;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: var(--secondary);
}

.cal-month-view .cal-header .cal-cell {
    text-transform: uppercase;
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
    color: #cccccc;
    opacity: 1;
}

.select-specific-calendar {
    margin: 10px -15px 0;
}

.discount-amount {
    input {
        width: 100%;
        line-height: 2.4;
        outline: none;
        border: none;
        padding: 0 10px;
        text-align: center;
    }
}

.page_width {
    max-width: 1400px;
    margin: 0px auto;
}

.top_actions {
    align-items: center;
    margin: 0 auto 10px;
}

.cntnt_wrpr {
    flex-flow: column;
    height: calc(100% - 232px);

    .sec {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
        background-color: white;
    }

    .fltrs_wrpr {
        height: 76px;
        align-items: center;
        padding: 0px 24px;
        justify-content: space-between;
        margin-bottom: 8px;
        flex-shrink: 0;

        .saved_fltrs {
            .pointer.form-control.bg-transparent.d-flex.justify-content-between.px-3.text-light {
                background-color: #0078ff !important;
                color: white !important;
            }
        }
    }

    .fltr_brdbrmbs {
        margin-bottom: 18px;
        height: 44px;
        align-items: center;
        padding: 0px 14px;
        flex-shrink: 0;

        .brdcrmb {
            background-color: #77d9e2;
            height: 24px;
            align-items: center;
            border-radius: 20px;
            font-size: 10px;
            color: white;
            padding: 0px 12px;
            margin: 0px 7px;

            .dlt {
                display: block;
                height: 9px;
                width: 9px;
                background-image: url($assetsPath + "/images/x-white.png");
                background-size: 100%;
                background-repeat: no-repeat;
                margin-left: 8px;
                cursor: pointer;
            }
        }

        .save_fltr {
            margin-left: 30px;
            align-items: center;
            font-size: 14px;
            color: #0078ff;
            padding: 5px 12px 4px;
            border-radius: 20px;
            cursor: pointer;

            &:hover {
                background-color: #eee;
            }

            &::before {
                content: "";
                display: block;
                height: 13px;
                width: 13px;
                margin-right: 12px;
                background-repeat: no-repeat;
                background-image: url($assetsPath + "/images/plus-blue.png");
                margin-top: -2px;
            }
        }
    }
}

app-order-list {
    .custom-accordion {
        margin-bottom: 20px;

        .card-header {
            padding: 20px;
        }
    }

    .fa-chevron-down {
        transition: 0.3s all;
    }

    .panel-open {
        .fa-chevron-down {
            transform: rotate(-180deg) translateY(5px);
        }

        .panel-collapse {
            overflow: visible !important;
        }
    }
}

app-driver-details,
app-customer-details {
    .rating {
        margin: 0 auto 40px;
        width: 130px;

        &-star {
            width: 26px;
            font-size: 20px;
        }
    }

    .list {
        color: #bbb;
        padding: 0 20px;
        margin-bottom: 20px;

        &-item {
            margin-bottom: 6px;
            font-size: 13px;
        }
    }

    .company-view-more {
        font-size: 13px;
    }

    .avatar-wrapper {
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 130px;
        height: 130px;
    }

    .form {
        label:not(.btn) {
            padding-left: 16px;
            font-size: 13px;
            color: #888;
        }
    }
}

.small-italic {
    font-style: italic;
    font-size: 10px;
    margin-bottom: 20px;
}

.charts-list {
    list-style-type: none;
    padding: 0;

    li {
        box-shadow: 0px 2px 6px #0000000f;
        border-left: 6px solid;
        background-color: #fff;
        font-size: 16px;
        padding: 14px 26px;
        width: calc(100% / 3 - 60px / 3);

        span {
            font-size: 45px;
        }

        .chart-container {
            height: 90px;
            width: 90px;
        }
    }
}

.submenu-popup-holder {
    display: none;
}

.bg-purple {
    background-color: var(--purple);
}

.new-route-fields-wrpr {
    margin-top: -60px;
    padding: 0 38px;

    .form-control {
        color: #d8d8d8;

        i:not(.fa-chevron-right) {
            font-size: 20px;
        }
    }
}

.form-control.fc-68 {
    cursor: pointer;
    height: 68px;
    line-height: 68px;
}

.zones-list {
    list-style-type: none;
    margin-top: 20px;
    padding: 0;

    li {
        cursor: pointer;
        margin-bottom: 20px;
    }
}

.add-pickup-delivery-popup {
    max-width: 626px;

    .hdr h4 {
        margin-top: 30px;
        margin-left: 10px;
        font-size: 23px;

        span {
            color: var(--secondary);
            font-size: 12px;
        }

        &.add-pickup-delivery-title {
            color: var(--primary);
        }

        &.add-delivery-title {
            color: var(--success);
        }

        &.add-pickup-title {
            color: var(--purple);
        }
    }

    .hdr .form-group {
        position: absolute;
        right: 70px;
        top: 20px;
        width: 112px;
    }
}

.assign-transfer-modal {
    max-width: 1035px;
}

.customer-payment-modal {
    max-width: 800px;
}

.ifield-iframe.form-control {
    padding: 0;
    border: none;
    height: 52px;
    margin: -4px;
    width: calc(100% + 8px);
}

.ng-select .ng-select-container {
    background-color: transparent !important;
    border: none;
    height: 100%;
}

.ng-select .ng-select-container .ng-value-container {
    line-height: 33px;
}

.ng-select .ng-select-container .ng-value-container,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-left: 0;
    padding-top: 0;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder,
.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-placeholder {
    color: var(--light);
    padding: 0;
}

.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-input {
    padding: 0;
}

.ng-select .ng-arrow-wrapper .ng-arrow,
.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    border-color: var(--light);
    border-width: 0 2px 2px 0;
    height: 8.5px !important;
    width: 8.5px !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    transform: rotate(45deg) translateY(-2px);
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    transform: rotate(-135deg) translate(-4px, -2px);
}

.cursor-grabbing {
    cursor: grabbing;
}

app-routes app-basic-datepicker .form-control:not(.opened) {
    background-color: transparent;
}

.drop-off-fields {
    .fa-clock,
    .fa-calendar-day {
        color: var(--success);
    }
}

.pick-up-fields {
    .fa-clock,
    .fa-calendar-day {
        color: var(--purple);
    }
}

.delivery-chain {
    padding-top: 30px;
    margin: 0 40px;
    width: 153px;

    > div {
        height: 4px;
        width: calc(14px + 33px);

        &.assigned {
            border-radius: 50%;
            height: 14px;
            position: relative;
            width: 14px;
        }
    }

    .label {
        display: none;
        position: absolute;
        bottom: 160%;
        left: 50%;
        color: #fff;
        font-size: 10px;
        transform: translateX(-50%);
        white-space: nowrap;
        padding: 2px 10px;
        border-radius: 11px;
        font-weight: 600;
        &::after {
            content: "";
            display: block;
            position: absolute;
            height: 10px;
            width: 10px;
            z-index: -1;
            bottom: 2px;
            left: 50%;
            transform: rotate(-45deg) translateX(-8px);
        }
    }

    .open,
    .open .circle,
    .open .label,
    .open .label::after {
        background-color: #ff4e4e;
    }

    .assigned,
    .assigned .label,
    .assigned .label::after {
        background-color: #00bad7;
    }

    .in-transit,
    .in-transit .circle,
    .in-transit .label,
    .in-transit .label::after {
        background-color: #4cb75c;
    }

    .delivered,
    .delivered .circle,
    .delivered .label,
    .delivered .label::after {
        background-color: #AAB8C1;
    }

    .open,
    .in-transit,
    .delivered {
        position: relative;

        .circle {
            border-radius: 50%;
            height: 14px;
            width: 14px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .open .circle {
        left: 0;
    }

    .in-transit .circle,
    .delivered .circle {
        right: 0;
    }

    &.is-opened {
        .open .label {
            display: block;
        }

        .open,
        .assigned,
        .in-transit,
        .delivered,
        .in-transit .circle,
        .delivered .circle,
        .assigned .label,
        .in-transit .label,
        .delivered .label {
            background-color: #d9d9d9;
        }
    }

    &.is-assigned {
        .assigned .label {
            display: block;
        }

        .in-transit,
        .delivered,
        .in-transit .circle,
        .delivered .circle,
        .in-transit .label,
        .delivered .label {
            background-color: #d9d9d9;
        }
    }

    &.is-in-transit {
        .in-transit .label {
            display: block;
        }

        .delivered,
        .delivered .circle,
        .delivered .label {
            background-color: #d9d9d9;
        }
    }

    &.is-delivered {
        .delivered .label {
            display: block;
        }
    }
}

.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

.w100 {
    width: 100px;
}

.w100p {
    width: 100%;
}

.w200 {
    width: 200px;
}

.w300 {
    width: 300px;
}

.w400 {
    width: 400px;
}

.table-top-actions {
    .status-dropdovn-wrpr {
        .fa-user-circle {
            margin-right: 6px;
            font-size: 20px;
            vertical-align: text-top;
        }
    }
    background-color: #fff;
    padding: 20px;
    border-bottom: 1.54px solid #e6e7e9;
    border-right: 1.54px solid #e6e7e9;
}

.btn-caret {
    background-image: none;
    border-left: 0;
    background-color: #07a4c3;
}
