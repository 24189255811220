@use "common/var" as *;

.dropdown-toggle {
  &::after {
    height: 10px;
    width: 10px;
    border-top: none;
    border-right: none;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
    margin-left: 24px;
    display: none;
  }

  &.has-toggle {
    &::after {
      display: inline-block;
    }
  }
}

.dropdown-full-width+.dropdown-menu {
  right: 0 !important;
}

.dropdown-menu {
  border: 1px solid $divider;
  border-radius: 4px;

  .dropdown-item {
    line-height: 1.4em;
    font-size: 1rem;
    padding-top: 8px;
    padding-bottom: 8px;
    white-space: unset;
    .icon {
      display: inline;
      vertical-align: middle;
      margin-right: 14px;
    }
  }
}

// .dropdown-menu {
//     .dropdown-item {
//         color: #b3b3b3;
//         font-size: 14px;
//         cursor: pointer;
//         padding: 0.25rem 1rem;

//         &:active {
//             background: initial;
//         }
//     }

//     .custom-control-input:checked ~ .custom-control-label::before {
//         color: currentColor;
//         border-color: currentColor;
//         background-color: currentColor;
//     }
// }