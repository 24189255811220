 .ngb-dp-header {
   background: $paper !important;
 }

 .ngb-dp-arrow {
   width: 8px !important;
   height: 45px !important;

   .ngb-dp-navigation-chevron {
     width: 8px !important;
     height: 8px !important;
   }
 }

 .ngb-dp-weekdays {
   color: $body-color !important;
   background: transparent !important;
   border-bottom: 0 !important;
 }

 .ngb-dp-weekday {
   font-size: 12px !important;
   width: 40px !important;
   height: 40px !important;
   color: var(--bs-dropdown-color) !important;
   font-style: normal !important;
 }

 .ngb-dp-day {
   font-size: 12px !important;
   width: 40px !important;
   height: 40px !important;
 }



 .ngb-dp-arrow-btn {
   color: inherit;
 }

 ngb-datepicker .ngb-dp-month-name {
   background-color: transparent;
 }

 ngb-datepicker .ngb-dp-navigation-select {
   flex: 0;
 }

 ngb-datepicker-navigation-select>.form-select {
   width: 80px;
   margin: 0 2px;
 }