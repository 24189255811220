.badge {
    padding: 4px 12px;

    &.bg-primary:not(.bg-solid) {
        background-color: rgba($color: $primary, $alpha: 0.15) !important;
        color: $primary;
    }

    &.bg-secondary:not(.bg-solid) {
        background-color: rgba($color: $secondary, $alpha: 0.15) !important;
        color: $secondary;
    }

    &.bg-success:not(.bg-solid) {
        background-color: rgba($color: $success, $alpha: 0.15) !important;
        color: $success;
    }

    &.bg-warning:not(.bg-solid) {
        background-color: rgba($color: $warning, $alpha: 0.15) !important;
        color: $warning;
    }

    &.bg-danger:not(.bg-solid) {
        background-color: rgba($color: $danger, $alpha: 0.15) !important;
        color: $danger;
    }

    &.bg-info:not(.bg-solid) {
        background-color: rgba($color: $info, $alpha: 0.15) !important;
        color: $info;
    }

    &.bg-dark:not(.bg-solid) {
        background-color: rgba($color: $dark, $alpha: 0.15) !important;
        color: $dark;
    }

    &.bg-light:not(.bg-solid) {
        background-color: $light !important;
        color: $body-color;
    }

    &.badge-xl {
        font-size: 1.8em;
    }

    &.badge-lg {
        font-size: 1.2em;
    }

    &.badge-md {
        font-size: 1em;
    }

    &.badge-xs {
        font-size: 0.6em;
    }
}