.nav-tabs {
  border: none;
  .nav-link {
    color: $text-muted;
    padding: 8px 16px;
    border: none;
    border-bottom: 5px solid transparent;
    &:focus {
      border-bottom-color: transparent;
    }
    &.active,
    &:hover {
      border-bottom-color: $primary !important;
      outline: none;
      background: transparent;
      color: $primary;
    }
  }
  &.show {
    .nav-link {
      border-bottom-color: $primary !important;
      border-color: transparent;
      outline: none;
      color: $primary;
    }
  }
}
