.table {
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border-color: $divider;
    }

    td {
        padding: 8px 12px;
        vertical-align: middle;
    }

    th {
        vertical-align: middle;
        padding: 16px 12px;
    }

    &.no-border {
        tr:not(.has-border) {
            td,
            th {
                border: none;
            }
        }
    }
}
